import { getLayoutData, getPageData } from './api/api';
import { CONTENT_TYPES as COMMON_CONTENT_TYPES } from '@repo/utils/constants';
import { CONTENT_TYPES as DESIGN_CONTENT_TYPES } from "@/utils/constants";
import ExtendedContent from "@/components/common/ExtendedContent";
import {StickyHeading} from "@repo/ui";
export default function Home({ data, globals, locale }) {
	const contentData = data?.content || []
	const stickyHeadingsSelectors = data?.stickyHeadingsSelector || [];

	return (
		<>
			<StickyHeading stickyHeadingsSelectors={stickyHeadingsSelectors} isVisible />
			<ExtendedContent content={contentData} locale={locale} globals={globals} isSinglePage={true} hidePleoTags={true} />
		</>
	);
}

const SINGLE_PAGES_QUERY = `
	query GetHomePageData($locale: SiteLocale) {
		allDesignSinglePages(locale: $locale, filter: {slug: {eq: "home"}}) {
            singlePageName
            slug
			description
			_seoMetaTags {
				attributes
				content
				tag
			}
			themeLight {
				mainPrimary {
					hex
				}
				mainSecondary {
					hex
				}
				mainTertiary {
					hex
				}
				extraPrimary {
					hex
				}
				extraSecondary {
					hex
				}
				extraTertiary {
					hex
				}
			}
			themeDark {
				mainPrimary {
					hex
				}
				mainSecondary {
					hex
				}
				mainTertiary {
					hex
				}
				extraPrimary {
					hex
				}
				extraSecondary {
					hex
				}
				extraTertiary {
					hex
				}
			}
			stickyHeadingsSelector {
			... on ${COMMON_CONTENT_TYPES.STICKY_HEADING} {
				module {
					__typename
					... on ${COMMON_CONTENT_TYPES.NEWS_THUMBNAILS} {
						id
					}
					... on ${COMMON_CONTENT_TYPES.DESCRIPTION_LOGO_PLEO} {
						id
					}
					... on ${COMMON_CONTENT_TYPES.HOW_WE_CREATE_STORY} {
						id
					}
					... on ${COMMON_CONTENT_TYPES.COMMON_BLOB_SLOGAN} {
						id
					}
					... on ${COMMON_CONTENT_TYPES.FEATURED_PROJECTS} {
						id
					}
					... on ${COMMON_CONTENT_TYPES.WHO_WE_WORKED_WITH} {
						id
					}
				}
				headingText
				}
			}
			content {
				__typename
				...on ${COMMON_CONTENT_TYPES.VIDEO_BANNER} {
				    id
				    video
					banner{
					  variant
					  header{
						text
						breaks{
						  image{
							url
							alt
						  }
						}
					  }
                        text{
							id
							text{
								text
								breaks{
									id
									image{
										url
										alt
									}
								}
							}
					  	}
					}
				}
				... on ${COMMON_CONTENT_TYPES.CHECK_OTHERS} {
					id
					contactHeader
					checkOthersHeader
					isColorfulBackground
					text {
                      text {
                        text
                        breaks {
                          image{
                            url
                          }
                        }
                      }
                    }
					links {
					  ... on ${DESIGN_CONTENT_TYPES.SINGLE_PAGE} {
						slug
						name
					  }
					  ... on ${DESIGN_CONTENT_TYPES.ALL_PROJECTS_PAGE} {
						slug
						name
					  }
					}
			  	}
				...on ${COMMON_CONTENT_TYPES.DESCRIPTION_SLOGAN} {
					id
					description
					side
					paddings: padding{
						top
						bottom
					}
					colorScheme {
						colorScheme
					}
				}
				... on ${COMMON_CONTENT_TYPES.HORIZONTAL_HEADER} {
					id
					title
					text
					paddings {
						top
						bottom
					}
					colorScheme {
						colorScheme
					}
					transparentBackground
					direction
				}
				... on ${DESIGN_CONTENT_TYPES.SINGLE_ASSET} {
					id
					title
					paddings{
						top
						bottom
					}
					colorScheme{
						colorScheme
					}
					externalVideoUrl {
						url
					}
					asset {
						url
						mimeType
						id
						width
						height
					}
					carouselText
					animation3d
					assetVariant
					animationExpandOnScroll
					backgroundColor {
						hex
					}
					fullScreenVideo{
					  link{
						url
					  }
					}
				}
				... on ${COMMON_CONTENT_TYPES.TEXT_MODULE} {
					id
					chooseTextModuleVariants {
						__typename
						... on ${COMMON_CONTENT_TYPES.QUOTE_BLOCK} {
					quote
					author
					id
					paddings{
						top
						bottom
					}
					colorScheme{
						colorScheme
					}
				}
				... on ${COMMON_CONTENT_TYPES.TEXT_CENTERED_BLOCK} {
					text
					id
					paddings{
						top
						bottom
					}
					colorScheme{
						colorScheme
					}
				}
				... on ${COMMON_CONTENT_TYPES.HEADER_DESCRIPTION_TWO_COLUMNS_BLOCK} {
					header
					description
					id
					paddings{
						top
						bottom
					}
					colorScheme{
						colorScheme
					}
				}
				... on ${COMMON_CONTENT_TYPES.HEADER_DESCRIPTION_HALF_WIDTH_BLOCK} {
					header
					description
					id
					paddings{
						top
						bottom
					}
					colorScheme{
						colorScheme
					}
				}
				... on ${COMMON_CONTENT_TYPES.TEXT_BREAK} {
					id
					text
					colorScheme{
						colorScheme
					}
					paddings {
						top
						bottom
					}
					breaks {
						... on ${COMMON_CONTENT_TYPES.IMAGE_BREAK} {
                            image {
                                url
                            }
				        }
				    }
				}
				... on ${COMMON_CONTENT_TYPES.TYPOGRAPHY_BIG} {
					text
					paddings{
						top
						bottom
					}
					colorScheme {
						colorScheme
					}
				}
				... on ${COMMON_CONTENT_TYPES.TYPOGRAPHY_MEDIUM_TEXT} {
					id
					text
					align
					paddings {
						top
						bottom
					}
					colorScheme {
						colorScheme
					}
				}
				}
				}
			... on ${DESIGN_CONTENT_TYPES.SOCIAL_MEDIA} {
				id
				colorScheme {
					colorScheme
				}
				paddings {
					top
					bottom
				}
				socialMedia {
					title
					link
				}
			}
			... on ${COMMON_CONTENT_TYPES.WHO_WE_WORKED_WITH} {
				id
				header
				description
				bottomText
				paddings {
					top
					bottom
				}
				video {
					url
					alt
				}
				items {
					id
					text
				}
				colorScheme {
					colorScheme
				}
			}
			... on ${COMMON_CONTENT_TYPES.DESCRIPTION_LOGO_PLEO} {
				id
				header
				description
				button {
					text
					link {
						... on DesignSinglePageRecord {
							slug
						}
					}
				}
				paddings {
					top
					bottom
				}
			}
			... on ${COMMON_CONTENT_TYPES.COMMON_BLOB_SLOGAN} {
				id
				slogans{
					id
					text
					align
				}
				paddings{
					top
					bottom
				}
				servicesPages{
					id
					card {
						header
						description
						icon{
							url
							alt
						}
						button {
							text
							link {
								... on ${DESIGN_CONTENT_TYPES.ALL_PROJECTS_PAGE} {
				__typename
				slug
			}
			... on ${COMMON_CONTENT_TYPES.SERVICE_PAGE_RECORD} {
				__typename
				slug
			}
			... on ${DESIGN_CONTENT_TYPES.SINGLE_PAGE} {
				__typename
				slug
			}
			... on ${DESIGN_CONTENT_TYPES.SINGLE_PROJECT} {
				__typename
				slug
			}
			}
			}
			}
			}
			}
			... on ${COMMON_CONTENT_TYPES.FEATURED_PROJECTS} {
				id
				text
				scaleXAxisVariant
				paddings {
					top
					bottom
				}
				button {
					text
					link {
						... on DesignAllProjectsPageRecord {
							slug
						}
					}
				}
				projects {
					id
					__typename
					indent
					grid {
						... on ${DESIGN_CONTENT_TYPES.SINGLE_PROJECT} {
				__typename
				id
				name
				slug
				description
				highlight
				projectColor {
					hex
				}
				project
				projectCategories {
					projectCategory
					projectCategoryId
				}
				projectServiceRange {
					serviceName
				}
				year
				themeLight {
					mainPrimary {
						hex
					}
					mainSecondary {
						hex
					}
					mainTertiary {
						hex
					}
					extraPrimary {
						hex
					}
					extraSecondary {
						hex
					}
					extraTertiary {
						hex
					}
				}
				themeDark {
					mainPrimary {
						hex
					}
					mainSecondary {
						hex
					}
					mainTertiary {
						hex
					}
					extraPrimary {
						hex
					}
					extraSecondary {
						hex
					}
					extraTertiary {
						hex
					}
				}
				coverPortrait {
					url
				}
				coverLandscape {
					url
				}
				video {
					url
				}
				description
				bannerWhiteLogo
				bannerWhiteMenu
			}
			}
			}
			}
			... on ${COMMON_CONTENT_TYPES.NEWS_THUMBNAILS} {
				id
				header
				button {
					text
					link{
						__typename
						... on DesignSinglePageRecord{
							slug
						}
					}
				}
				displayGridLines
				gridLinesEndModule {
                    ... on ${COMMON_CONTENT_TYPES.HOW_WE_CREATE_STORY} {
                        id
                    }
                }
				chooseNews {
					__typename
					blogPageName
					slug
					wordsCount
					_createdAt
					themeLight {
						mainPrimary {
							hex
						}
						mainSecondary{
							hex
						}
					}
					themeDark {
						mainPrimary {
							hex
						}
						mainSecondary{
							hex
						}
					}
					pleoCategory
					categories
					thumbnailImage {
						url
						alt
						width
						height
						focalPoint {
							x
							y
						}
					}
				}
				paddings {
					top
					bottom
				}
			}
			... on ${COMMON_CONTENT_TYPES.HOW_WE_CREATE_STORY} {
				id
				slogan
				paddings {
					top
					bottom
				}
				button {
					text
					link {
						... on DesignSinglePageRecord {
							slug
						}
					}
				}
				items {
					id
					tag
					text
					slug {
						__typename
						... on DesignSinglePageRecord {
				slug
			}
			}
			image {
			url
			alt
			width
			height
			}
			hoverImage {
			url
			alt
			width
			height
			}
			}
			}
			}
		}
	}
`;

export async function getStaticProps(context) {
	const locale = context?.locale || 'pl';
	const data = await getPageData('allDesignSinglePages', 'home', SINGLE_PAGES_QUERY, locale);
	const { globals, cookies, footer, menu } = await getLayoutData(locale)

	return {
		props: {
			data,
			globals,
			cookies,
			locale,
			footer,
			menu
		},
	};
}
